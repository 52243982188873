<template>
  <div>
    <div class="card plan-box overflow-hidden">
      <div
        class="p-3"
        :class="{
          'bg-primary': pasarela == 'paypal',
          'bg-info': pasarela == 'mercadoPago' 
        }"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <h5 class="text-white">Saldo</h5>
          </div>
          <div class="flex-shrink-0 text-white">
            <div class="dropdown">
              <a
                class="dropdown-toggle text-reset"
                href="#"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fw-semibold">Pasarela: </span>
                <span>
                  {{ pasarela == 'mercadoPago' ? 'Mercado pago' : '' }}
                  {{ pasarela == 'paypal' ? 'PayPal' : '' }}
                  <i class="mdi mdi-chevron-down ms-1"></i>
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a
                  class="dropdown-item"
                  href="javascript: void(0);"
                  :class="{'active': pasarela=='mercadoPago'}"
                  @click="
                    pasarela='mercadoPago',
                    recargarPasarela()
                  "
                >
                  Mercado Pago
                </a>
                <a
                  class="dropdown-item"
                  href="javascript: void(0);"
                  :class="{'active': pasarela=='paypal'}"
                  @click="
                    pasarela='paypal',
                    recargarPasarela()
                  "
                >
                  Paypal
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div class="py-2 text-right">
          <h1 class="mb-0">
            {{formatoMoneda(cuenta_master.creditos_sgcm)}} /
            <span class="font-size-14">
              USD disponible{{cuenta_master.creditos_sgcm > 1 ? 's' : ''}}
            </span> <br>
            <button class="btn btn-info btn-sm" @click="cargarCuentaMaster(true)">
              <i class="bx bx-revision"></i>
              Refrescar saldo
            </button>
          </h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-7" :class="pasarela == 'paypal' ? 'mb-3' : ''">
            <div class="input-group">
              <span class="input-group-text">Recargar Saldo</span>
              <select class="form-select" v-model="id_precio" @change="recargarPasarela()">
                <option
                  v-for="precio in precios"
                  :key="precio.id" :value="precio.id"
                >
                  ${{precio.dolares}} USD
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-5">
            <div ref="paypal"></div>
          </div>
        </div>
        <div ref="mercadoPago" id="wallet_container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import accounting from 'accounting/accounting.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import CuentaSrv from '@/services/CuentaSrv.js'
import Swal from 'sweetalert2'
import axios from 'axios'

export default {
  name: 'WSaldo',
  data() {
    return {
      pasarela: 'mercadoPago',    // mercadoPago || paypal
      paypal_mode: "sandbox",
      paypal_client_id: '',
      paypal_secret: '',
      currency_paypal: 'USD',

      mercadoPago_mode: "sandbox",
      mercadoPago_public_key: "",
      mercadoPago_access_token: "",
      id_preference: null,

      id_precio: 1,
      
      precios: [
        {id:1, dolares: 15},
        {id:2, dolares: 20},
        {id:3, dolares: 40},
        {id:4, dolares: 100}
      ],

      cuenta_master: {
        id: null,
        nombre: "",
        apellido_materno: "",
        apellido_paterno: "",
        telefono: "",
        correo: "",
        creditos_sgcm: 0,
        ultimo_inicio_sesion: ""
      }
    }
  },
  created: function() {
    var self = this

    self.cargarCuentaMaster(false, function() {
      let prom1 = SistemaSrv.mercadoPago()
      let prom2 = SistemaSrv.paypal()

      axios.all([prom1, prom2]).then(response => {
        let res1 = response[0].data, res2 = response[1].data

        self.mercadoPago_mode = res1.mercadoPago_mode
        self.mercadoPago_public_key = res1.mercadoPago_public_key
        self.mercadoPago_access_token = res1.mercadoPago_access_token

        self.paypal_mode = res2.paypal_mode
        self.paypal_client_id = res2.paypal_client_id
        self.paypal_secret = res2.paypal_secret

        switch(self.pasarela) {
          case 'mercadoPago':
            self.mercadoPago()
          break;
          case 'paypal':
            self.paypal()
          break;
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar mercado pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    })
  },
  methods: {
    cargarCuentaMaster: function(mensaje = false, callback) {
      var self = this

      CuentaSrv.cuentaMasterJSON().then(response => {
        self.cuenta_master = response.data
        if(mensaje) iu.msg.info('Se recargó correctamente la cuenta master')

        if(callback) callback()
      }).catch(error => {
        let mensaje 
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la cuenta maestra'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    mercadoPago: function() {
      var self = this

      var script = document.createElement('script')

      let saldo = self.precios.find((precio) => {
        return precio.id == self.id_precio
      })

      if(saldo == null) {
        iu.msg.warning('Seleccionar los dolares permitidos')
        return
      }

      let aprobado = this.$router.resolve({ name: 'aprobado' });
      let pendiente = this.$router.resolve({ name: 'pendiente' });
      let fallido = this.$router.resolve({ name: 'fallido' });

      let params = {
        id_cliente: self.cuenta_master.id, 
        dolares: saldo.dolares,
        ruta_aprobado: window.location.origin+aprobado.href,
        ruta_pendiente: window.location.origin+pendiente.href,
        ruta_fallido: window.location.origin+fallido.href,
      }

      SistemaSrv.mercadoPagoPreference(params).then(response => {
        self.id_preference = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar mercado pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally( () => {
        script.src = "https://sdk.mercadopago.com/js/v2"
      })

      script.addEventListener('load', self.setLoadedMercadoPago)
      document.body.appendChild(script)
    },

    paypal: function() {
      var self = this

      var script = document.createElement('script')
      script.src = `https://www.paypal.com/sdk/js?currency=${self.currency_paypal}&client-id=${self.paypal_client_id}&disable-funding=card`
      script.addEventListener('load', self.setLoadedPaypal)
      document.body.appendChild(script)
    },

    cadenaPago(idCliente, dolares) {
      return `app_sgcm-cln_${idCliente}-crd_${dolares}`
    },

    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },

    setLoadedMercadoPago: function() {
      var self = this

      if (window.paypal) self.$refs.paypal.innerHTML=""
      if (window.mp) self.$refs.mercadoPago.innerHTML=""

      window.mp = new MercadoPago(self.mercadoPago_public_key, {
        locale: 'es-MX'
      })

      window.mp.bricks().create("wallet", "wallet_container", {
        initialization: {
            preferenceId: self.id_preference,
            redirectMode: "modal",
        },
        callbacks: {
          onReady: () => {},
          onSubmit: () => {},
          onError: (error) => console.error(error),
        },
      })
    },

    setLoadedPaypal: function () {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      })

      if (window.paypal) self.$refs.paypal.innerHTML=""
      if (window.mp) self.$refs.mercadoPago.innerHTML=""

      let saldo = self.precios.find((precio) => {
        return precio.id == self.id_precio
      })

      if(saldo == null) {
        iu.msg.warning('Seleccionar los dolares permitidos')
        return
      }
    
      window.paypal.Buttons({
        style: {
          size: 'small',
          color: 'blue',
          shape: 'pill',
          label: 'pay',
          height: 40,
        },createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: `SGCM ${saldo.dolares} USD`,
                amount: {
                  value: saldo.dolares,
                },
                custom_id: self.cadenaPago(self.cuenta_master.id, saldo.dolares),
              },
            ],
          })
        },onApprove: async (data, actions) => {
          const order = await actions.order.capture()
          // console.log(order)
          swal.fire("Pago realizado!", "La recarga de saldo ha sido satisfactoria; <br> \"<strong>refrescar saldo</strong>\", <br> hasta ver reflejado los dólares disponibles, esto puede tardar algunos segundos.", "success")
        },onError: (err) => {
          console.log(err)
          swal.fire("Pago rechasado!", "Pago rechazado verificar su cuenta", "error")
        },
      }).render(self.$refs.paypal)
    },
    recargarPasarela:function () {
      var self = this

      switch(self.pasarela) {
        case 'mercadoPago':
          self.mercadoPago()
        break;
        case 'paypal':
          self.paypal()
        break;
      }
    }
  },
}
</script>

<style scoped>
.bg-primary {
  background: #0F70BA !important;
}
</style>