<template>
  <Layout tituloPagina="Sistema | Cuenta | Cambiar de plan Inventario">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Selección de plan</h4>
          </div>
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive">
              <table class="tblRouters table table-hover">
                <thead>
                  <tr>
                    <th><span class="h3">Caracteristicas</span></th>
                    <th v-show="estado_modulo">
                      <span class="h3">Plan {{plan_inventario_contratado.nombre}}</span>
                      <br>
                      <big class="text-success">
                        Plan actual
                      </big>
                    </th>
                    <th>
                      <span class="h3">Plan {{plan_inventario_seleccionado.nombre}}</span><br>
                      <big class="text-info">
                        Nuevo plan seleccionado
                      </big>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Descripción</td>
                    <td style="min-width: 300px;" v-show="estado_modulo">{{plan_inventario_contratado.descripcion}}</td>
                    <td style="min-width: 300px;">{{plan_inventario_seleccionado.descripcion}}</td>
                  </tr>
                  <tr>
                    <td>Gestión de productos</td>
                    <td v-show="estado_modulo">{{plan_inventario_contratado.max_productos != -1 ? 'hasta '+plan_inventario_contratado.max_productos : 'Ilimitado'}}</td>
                    <td>{{plan_inventario_seleccionado.max_productos != -1 ? 'hasta '+plan_inventario_seleccionado.max_productos : 'Ilimitado'}}</td>
                  </tr>
                  <tr>
                    <td>Almacenamiento</td>
                    <td v-show="estado_modulo">{{plan_inventario_contratado.max_almacenamiento != -1 ? 'hasta '+plan_inventario_contratado.max_almacenamiento+'MB' : 'Ilimitado'}}</td>
                    <td>{{plan_inventario_seleccionado.max_almacenamiento != -1 ? 'hasta '+plan_inventario_seleccionado.max_almacenamiento+'MB' : 'Ilimitado'}}</td>
                  </tr>
                  <tr>
                    <td>Precio mensual</td>
                    <td v-show="estado_modulo">
                      ${{plan_inventario_contratado.precio_mensual}} USD
                    </td>
                    <td>
                      ${{plan_inventario_seleccionado.precio_mensual}} USD
                    </td>
                  </tr>
                  <tr>
                    <td>Precio anual</td>
                    <td v-show="estado_modulo">
                      ${{plan_inventario_contratado.precio_anual}} USD <br>
                      <strong>{{costoAnualPocentaje(plan_inventario_contratado.precio_mensual, plan_inventario_contratado.precio_anual)}}</strong>
                    </td>
                    <td>
                      ${{plan_inventario_seleccionado.precio_anual}} USD <br>
                      <strong>{{costoAnualPocentaje(plan_inventario_seleccionado.precio_mensual, plan_inventario_seleccionado.precio_anual)}}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary"
              @click="$router.go(-1)">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button class="btn btn-success" @click="actualizarModuloPlan()">
              <i class="mdi mdi-refresh"></i>
              {{estado_modulo ? 'Cambiar' : 'Activar'}} plan Inventario
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <w-saldo></w-saldo>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import WSaldo from '@/views/sistema/cuenta/WSaldo.vue'
import CuentaSrv from '@/services/CuentaSrv.js'
export default {
  name: 'ActualizarPlanInventario',
  components: { Layout, WSaldo },
  data() {
    return {
      id_plan_seleccionado: null,

      estado_modulo: false,

      plan_inventario_contratado: {
        id: null,
        descripcion: "",
        max_almacenamiento: 0,
        max_productos: 0,
        nombre: "",
        precio_anual: 0,
        precio_mensual: 0
      },

      plan_inventario_seleccionado: {
        id: null,
        descripcion: "",
        max_almacenamiento: 0,
        max_productos: 0,
        nombre: "",
        precio_anual: 0,
        precio_mensual: 0
      }
    }
  },

  created: function () {
    var self = this

    self.id_plan_seleccionado = self.$route.params.id_plan

    self.cargarEstado(function() {
      self.cargarPlanSeleccionado()
    })
  },
  methods: {
    actualizarModuloPlan:function() {
      var self = this 
      let datos = {
        id_plan: self.plan_inventario_seleccionado.id
      }

      if(self.plan_inventario_contratado.id != null) Object.assign(datos, {id_plan_actual: self.plan_inventario_contratado.id})

      CuentaSrv.actualizarModuloPlanContratado('inventario', datos).then(response => {
        let mensaje = self.estado_modulo ? 'Se cambio correctamente el plan seleccionado' : 'Se activo correctamente el plan seleccionado'
        iu.msg.success(mensaje)

        self.$router.push({ name: 'sistemaCuenta'})
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo activar o cambiar plan'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarEstado: function(callback) {
      var self = this

      CuentaSrv.estadoModulo('inventario').then(response => {
        self.estado_modulo = response.data

        if(self.estado_modulo == true) self.cargarPlanContratado()

        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlanContratado: function (){
      var self = this

      CuentaSrv.moduloPlanContratadoJSON('inventario').then(response => {
        self.plan_inventario_contratado = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlanSeleccionado:function () {
      var self = this

      CuentaSrv.moduloPlanJSON('inventario', self.id_plan_seleccionado).then(response => {
        self.plan_inventario_seleccionado = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    
    costoAnualPocentaje: function(precio_mensual, precio_anual){
      if(precio_anual < 0) return ''

      let total = precio_mensual * 12
      let porcentaje = ((total - precio_anual) * 100) / total

      if(porcentaje < 0) return ''

      return `Costo anual (incluye ${parseInt(porcentaje)}% de descuento)`
    }
  }
}
</script>

<style scoped>

</style>